<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title"><i class="ri-edit-line"></i> {{ `${$t('movement.inter_godown_program_management')} ${(id) ? $t('movement.modify') : $t('globalTrans.entry')}`}}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12" class="form-for-textarea">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset"  enctype="multipart/form-data">
                      <b-row>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Program No"  vid="program_no" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="program_no"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.program_no')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="program_no"
                                    v-model="formData.program_no"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Date"  vid="date" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="transfer_date"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('globalTrans.date')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    class="date-picker"
                                    id="transfer_date"
                                    v-model="formData.transfer_date"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="From Godown" vid="from_godown_infos_id" rules="required|min_value:1">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="from_godown_infos_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('movement.from_godown') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  @change="getCurrentStockBefore()"
                                  v-model="formData.from_godown_infos_id"
                                  :options="godownInfoList"
                                  id="from_godown_infos_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Fertilizer Name" vid="fertilizer_id" rules="required|min_value:1">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="fertilizer_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('movement.fertilizer_name') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  @change="getCurrentStockBefore()"
                                  v-model="formData.fertilizer_id"
                                  :options="fertilizerNameList"
                                  id="fertilizer_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="fiscal_year_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('seedFer.fiscal_year') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="formData.fiscal_year_id"
                                  :options="fiscalYearList"
                                  id="fiscal_year_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                      </b-row>
                      <b-row>
                          <b-col lg="12" sm="12" md='12'><u class="text-dark"><p class="head mb-2">{{ $t('movement.stock_details') }}</p></u></b-col>
                          <b-col lg="6" sm="12">
                            <ValidationProvider name="Current Stock"  vid="current_stock" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="current_stock"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('movement.current_stock')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="current_stock"
                                    v-model="formData.amount"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    :placeholder="loading ? 'Loading...' : ''"
                                    readonly
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                      </b-row>
                      <b-container style="min-height:150px">
                        <b-row>
                          <b-col>
                            <div class="text-right">
                              <b-button class="btn btn-sm btn-success mr-1" size="sm" @click="addList()"><i class="ri-add-line"></i>{{ $t('movement.add_new') }}</b-button>
                            </div>
                          </b-col>
                        </b-row>
                        <b-row class="">
                          <b-table-simple class="mt-3" hover bordered small caption-top responsive>
                            <b-thead head-variant="secondary">
                              <b-tr>
                                <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                                <b-th>{{ $t('movement.to_godown') }}</b-th>
                                <b-th>{{ $t('movement.transferred_amount') }}</b-th>
                                <b-th>{{ $t('movement.transport_agent') }}</b-th>
                                <b-th class="text-center">{{ $t('globalTrans.action') }}</b-th>
                              </b-tr>
                            </b-thead>
                            <b-tbody>
                              <b-tr v-for="(godown, index) in formData.details_info" :key='index'>
                                <b-td>{{ $n(index + 1) }}</b-td>
                                <b-td>
                                  <ValidationProvider  name="To Godown" vid="to_godown_infos_id" rules="required|min_value:1">
                                    <b-form-group
                                      slot-scope="{ valid, errors }"
                                      >
                                      <b-form-select
                                        plain
                                        @change="checkGodownExits($event, index)"
                                        v-model="godown.to_godown_infos_id"
                                        :options="godownInfoList"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        <template v-slot:first>
                                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                      </b-form-select>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                    </b-form-group>
                                  </ValidationProvider>
                                </b-td>
                                <b-td width='200px'>
                                  <ValidationProvider name="Transferred Amount" vid="amount" rules="required|min_value:1">
                                  <b-form-group
                                  slot-scope="{ valid, errors }"
                                  >
                                    <b-form-input
                                        @keyup="calCulateAmount(formData.details_info, index)"
                                        v-model="godown.amount"
                                        type="number"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        small
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                                  </ValidationProvider>
                                </b-td>
                                <b-td>
                                  <ValidationProvider name="Transport Agent" vid="transport_agent_id" rules="required|min_value:1">
                                    <b-form-group
                                      slot-scope="{ valid, errors }"
                                      >
                                      <b-form-select
                                        plain
                                        @change="checkTransExits($event, index)"
                                        v-model="godown.transport_agent_id"
                                        :options="transportAgentList"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        <template v-slot:first>
                                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                      </b-form-select>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                    </b-form-group>
                                  </ValidationProvider>
                                </b-td>
                                <b-td class="text-center">
                                  <b-button class="btn btn-sm btn-danger mr-1" size="sm" @click="removeList(index)"><i class="ri-delete-bin-line m-0"></i></b-button>
                                </b-td>
                              </b-tr>
                            </b-tbody>
                          </b-table-simple>
                        </b-row>
                      </b-container>
                      <div class="row mt-2">
                        <div class='col-md-4 offset-md-4'>
                          <b-button type="submit" variant="primary" class="mr-2 btn-block">{{ saveBtnName }}</b-button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { interProgramManageStote, interProgramManageUpdate, getStockByGodown, interProgramManageShow } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  components: {
    ValidationObserver,
    ValidationProvider
  },
  mounted () {
    flatpickr('.date-picker', {})
    core.index()
  },
  data () {
    return {
      saveBtnName: this.$route.query.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        transfer_date: '',
        program_no: '',
        from_godown_infos_id: 0,
        fertilizer_id: 0,
        available_amount: 0,
        available_amount_store: 0,
        amount: 0,
        fiscal_year_id: '',
        details_info: [
          {
            to_godown_infos_id: 0,
            amount: '',
            transport_agent_id: 0
          }
        ]
      },
      loading: false,
      id: ''
    }
  },
  created () {
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      const result = this.getFormData(this.$route.query.id)
      result.then(data => {
        Object.assign(this.formData, data)
        Object.assign(this.formData, { details_info: data.inter_godown_details })
        Object.assign(this.formData, { available_amount: data.total_avaiable_edit })
        this.getCurrentStock()
      })
    }
  },
  computed: {
    total_assing_to_godown: function () {
      return this.formData.amount - this.formData.available_amount_store
    },
    transportAgentList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.transportAgentList.filter(item => item.status === 1)
    },
    fertilizerNameList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
    },
    godownInfoList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.godownInfoList.filter(item => item.status === 1)
    },
    fiscalYearList: function () {
      const fiscalYears = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      const tempData = fiscalYears.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.text_bn, value: item.value }
        } else {
          return { text: item.text_en, value: item.value }
        }
      })
      return tempData
    }
  },
//   watch: {
//     'formData.from_godown_infos_id': function (newVal, oldVal) {
//       if (newVal !== oldVal) {
//         this.getCurrentStock()
//       }
//     },
//     'formData.fertilizer_id': function (newVal, oldVal) {
//       if (newVal !== oldVal) {
//         this.getCurrentStock()
//       }
//     }
//   },
  methods: {
    async getCurrentStockBefore () {
      this.formData.details_info = [
        {
          to_godown_infos_id: 0,
          amount: '',
          transport_agent_id: 0
        }
      ]
      this.getCurrentStock()
    },
    async createData () {
      this.loading = true
      let result = null
      if (this.$route.query.id) {
        result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${interProgramManageUpdate}/${this.$route.query.id}`, this.formData)
      } else {
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, interProgramManageStote, this.formData)
      }
      this.loading = false
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.$route.query.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$router.push('inter-godown-program-management')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    async getCurrentStock () {
        // this.formData.details_info = [
        //   {
        //     to_godown_infos_id: 0,
        //     amount: '',
        //     transport_agent_id: 0
        //   }
        // ]
        this.loading = true
        const param = { inter_godown_id: this.formData.id, from_godown_infos_id: this.formData.from_godown_infos_id, fertilizer_id: this.formData.fertilizer_id }
        const result = await RestApi.postData(seedFertilizerServiceBaseUrl, getStockByGodown, param)
        if (result.success) {
          this.formData.amount = result.data.current_stock_amount
          this.formData.available_amount = result.data.available_amount
          if (!this.$route.query.id) {
          }
          this.formData.available_amount_store = result.data.available_amount + result.data.existAmount
        } else {
          this.formData.amount = ''
          this.formData.available_amount = ''
          this.formData.available_amount_store = ''
        }
        this.loading = false
    },
    async getCurrentStockEdit () {
        this.loading = true
        const param = { from_godown_infos_id: this.formData.from_godown_infos_id, fertilizer_id: this.formData.fertilizer_id }
        const result = await RestApi.postData(seedFertilizerServiceBaseUrl, getStockByGodown, param)
        if (result.success) {
          this.formData.amount = result.data.current_stock_amount
          if (!this.$route.query.id) {
            this.formData.available_amount = result.data.available_amount
          }
          this.formData.available_amount_store = result.data.available_amount + result.data.existAmount
        } else {
          this.formData.amount = ''
          this.formData.available_amount = ''
          this.formData.available_amount_store = ''
        }
        this.loading = false
    },
    addList () {
      const item = {
            to_godown_infos_id: 0,
            amount: '',
            transport_agent_id: 0
          }
      this.formData.details_info.push(item)
    },
    removeList (index) {
      this.formData.details_info.splice(index, 1)
      this.formData.available_amount = this.getAllocateAmountEdit(this.formData.details_info)
    },
    calCulateAmount (val, index) {
      const allocatedAmount = this.formData.available_amount_store
      const totalset = val.map(o => o.amount).reduce((a, c) => {
        a = a ? parseInt(a) : 0
        c = c ? parseInt(c) : 0
        return a + c
      })
      if (allocatedAmount < totalset) {
        const currentAmt = totalset - this.formData.details_info[index].amount
        const availableAmount = (allocatedAmount - currentAmt) < 0 ? 0 : allocatedAmount - currentAmt
        this.formData.details_info[index].amount = availableAmount
        this.formData.available_amount = 0
      } else {
        this.formData.available_amount = allocatedAmount - totalset
      }
    },
    async getFormData (Id) {
      this.loading = true
      const result = await RestApi.getData(seedFertilizerServiceBaseUrl, `${interProgramManageShow}/${Id}`)
      this.loading = false
      if (result.success) {
        return JSON.parse(JSON.stringify(result.data))
      }
      this.loading = false
    },
    getAllocateAmountEdit (details) {
        if (details.length) {
            const totalset = details.map(o => o.amount).reduce((a, c) => {
              a = a ? parseInt(a) : 0
              c = c ? parseInt(c) : 0
              return a + c
            })
            return this.formData.available_amount_store - totalset
        } else {
            return this.formData.available_amount_store
        }
    },
    checkGodownExits (godownId, index) {
        if (this.formData.details_info.length) {
            this.formData.details_info.map((i, ind) => {
                if (index !== ind && godownId === i.to_godown_infos_id) {
                    this.formData.details_info[index].to_godown_infos_id = 0
                }
            })
        }
    },
    checkTransExits (transId, index) {
        if (this.formData.details_info.length) {
            this.formData.details_info.map((i, ind) => {
                if (index !== ind && transId === i.transport_agent_id) {
                    this.formData.details_info[index].transport_agent_id = 0
                }
            })
        }
    }
  }
}
</script>
<style scope>
  .head {
    font-size: 16px;
    font-weight: 700;
    color: #655f5f;
    margin-bottom: 0 !important;
  }
</style>
